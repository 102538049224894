// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
apiKey: "AIzaSyDTH6qR4t69AGUGBQoG8F4axMjjqCfeuWo",
  authDomain: "babateli.firebaseapp.com",
  projectId: "babateli",
  storageBucket: "babateli.appspot.com",
  messagingSenderId: "238247617959",
  appId: "1:238247617959:web:212c90cb6965c631a94282"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
